$(document).on('turbolinks:load', function() {
    var individuals_datatable = $('#individuals_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#individuals_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "first_name"},
            {"data": "last_name"},
            {"data": "address"},
            {"data": "designation"},
            {"data": "external_id"}
        ]
    });

    $('#individuals_datatable').on( 'click', 'tbody tr', function () {
        var id = individuals_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/individuals/'+id;
        }
    });


    var admin_individuals_datatable = $('#admin_individuals_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#admin_individuals_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "company"},
            {"data": "first_name"},
            {"data": "last_name"},
            {"data": "address"},
            {"data": "designation"},
            {"data": "external_id"}
        ]
    });

    $('#admin_individuals_datatable').on( 'click', 'tbody tr', function () {
        var id = admin_individuals_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/individuals/'+id;
        }
    });
});