$( document ).on('turbolinks:load', function() {
    setTimeout(function() {
        $('.alert').fadeOut("slow");
    }, 5000);


    $('#deleted').on( 'change', function () {
        window.location = window.location.href.split("?")[0] +"?deleted="+ this.checked;
    });

    window.enableSubmit = enableSubmit
    window.disableSubmit = disableSubmit

    function enableSubmit(){
        $("#submit_btn").prop('disabled', false);
    }

    function disableSubmit(){
        $("#submit_btn").prop('disabled', true);
    }
});
