$(document).on('turbolinks:load', function() {
    var incident_templates_datatable = $('#incident_templates_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#incident_templates_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "company"},
            {"data": "name"}
        ]
    });

    $('#incident_templates_datatable').on( 'click', 'tbody tr', function () {
        var id = incident_templates_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/incident_templates/'+id;
        }
    });


    $('#incident_template_form').on('click', '.remove_fields', function(event) {
        $(this).parent().find('input[type=hidden]').val(true);
        $(this).closest('fieldset').hide();
        return event.preventDefault();
    });

    $('#incident_template_form').on('click', '.add_fields', function(event) {
        var regexp, time;
        time = new Date().getTime();
        regexp = new RegExp($(this).data('id'), 'g');
        $(this).before($(this).data('fields').replace(regexp, time));
        return event.preventDefault();
    });

    $('#incident_template_company_id').on('change', function(){
        var company_id = $(this).val();
        window.location = window.location.href.split("?")[0] +"?company_id="+ company_id;
    });
});