$(document).on('turbolinks:load', function() {
    var locations_datatable = $('#locations_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#locations_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "name"},
            {"data": "description"}
        ]
    });

    $('#locations_datatable').on( 'click', 'tbody tr', function () {
        var id = locations_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/locations/'+id;
        }
    });


    var admin_locations_datatable = $('#admin_locations_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#admin_locations_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "company"},
            {"data": "name"},
            {"data": "description"}
        ]
    });

    $('#admin_locations_datatable').on( 'click', 'tbody tr', function () {
        var id = admin_locations_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/locations/'+id;
        }
    });
});