$(document).on('turbolinks:load', function() {
    var incidents_datatable = $('#incidents_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#incidents_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "order": [[ 0, "desc" ]],
        "columns": [
            {"data": "id", "visible": false},
            {"data": "incident_id"},
            {"data": "title"},
            {"data": "incident_template"},
            {"data": "incident_type"},
            {"data": "location"},
            {"data": "user"},
            {"data": "occurred_at_date"},
            {"data": "occurred_at_time"},
            {"data": "attachment", "sortable": false},
            {"data": "approved"}
        ]
    });

    $('#incidents_datatable').on( 'click', 'tbody tr', function () {
        var id = incidents_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/incidents/'+id;
        }
    });


    var admin_incidents_datatable = $('#admin_incidents_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#admin_incidents_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "order": [[ 0, "desc" ]],
        "columns": [
            {"data": "id", "visible": false},
            {"data": "company"},
            {"data": "incident_id"},
            {"data": "title"},
            {"data": "incident_template"},
            {"data": "incident_type"},
            {"data": "location"},
            {"data": "user"},
            {"data": "occurred_at_date"},
            {"data": "occurred_at_time"},
            {"data": "attachment", "sortable": false},
            {"data": "approved"}
        ]
    });

    $('#admin_incidents_datatable').on( 'click', 'tbody tr', function () {
        var id = admin_incidents_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/incidents/'+id;
        }
    });


    var incident_pending_approval_datatable = $('#incident_pending_approval_datatable').DataTable({
        "processing": false,
        "serverSide": false,
        "scrollX": true,
        "pagingType": "full_numbers",
    });

    $('#incident_pending_approval_datatable').on( 'click', 'tbody tr', function () {
        var id = incident_pending_approval_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/incidents/'+id;
        }
    });


    var incident_recent_datatable = $('#incident_recent_datatable').DataTable({
        "processing": false,
        "serverSide": false,
        "scrollX": true,
        "pagingType": "full_numbers",
    });

    $('#incident_recent_datatable tbody tr').on( 'click', function () {
        var id = incident_recent_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/incidents/'+id;
        }
    });
});