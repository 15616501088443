$(document).on('turbolinks:load', function() {
    var users_datatable = $('#users_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#users_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "id"},
            {"data": "first_name"},
            {"data": "last_name"},
            {"data": "email"},
            {"data": "phone"},
            {"data": "external_id"},
            {"data": "notes"},
            {"data": "role"},
            {"data": "employee"},
            {"data": "report_recipient"},
            {"data": "report_creator"},
            {"data": "report_approver"}
        ]
    });

    $('#users_datatable').on( 'click', 'tbody tr', function () {
        var id = users_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/users/'+id;
        }
    });


    var admin_users_datatable = $('#admin_users_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#admin_users_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "company"},
            {"data": "id"},
            {"data": "first_name"},
            {"data": "last_name"},
            {"data": "email"},
            {"data": "phone"},
            {"data": "external_id"},
            {"data": "notes"},
            {"data": "role"},
            {"data": "employee"},
            {"data": "report_recipient"},
            {"data": "report_creator"},
            {"data": "report_approver"}
        ]
    });

    $('#admin_users_datatable').on( 'click', 'tbody tr', function () {
        var id = admin_users_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/users/'+id;
        }
    });
});